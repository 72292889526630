import notification from "../utilitarios/notification.js";
import Loader from '../utilitarios/loader.js'

const $url = 'index.php?route=checkout/cart'

function checkQuantidade(quantity) {
	quantity = isNaN(quantity) ? 1 : quantity;
}

const cart = {
	'add': function(product_id, quantity) {

		checkQuantidade(quantity);

		$.ajax({
			url: `${$url}/add`,
			type: 'post',
			data: `product_id=${product_id}&quantity=${quantity}`,
			dataType: 'json',
			beforeSend: function() {
				$('#cart > button').button('loading');
			},
			complete: function() {
				$('#cart > button').button('reset');
			},
			success: function(json) {
				$('.alert, .text-danger').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
					//notification.success(json['success']);

					/*window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });*/

					Swal.fire("Sucesso", "" + json["success"] + "", "success");

					if (json['count']) {
						$('#box-carrinho [data-cart="count"]').text(json['count']);
					}
					if (json['total']) {
						$('#box-carrinho [data-cart="total"]').text(json['total']);
					}


				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
		return false;
	},
	'addOpcional': function(product_id, quantity, product_id_unique) {

		checkQuantidade(quantity);

		if (product_id_unique) {
			let options_values = $('#options-id-' + product_id_unique + ' input[type="radio"]:checked, #options-id-' + product_id_unique + ' input[type="checkbox"]:checked, #options-id-' + product_id_unique + ' select>option:selected');
			let dados = decodeURI($(options_values).serialize());
			try {
				var json_object = JSON.parse('{"' + decodeURI(dados.replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}');
				var productData = {'product_id' : product_id, 'quantity': quantity, 'option': json_object };
				var dados_ = $.param(productData);
			}
			catch(err) {
				var dados_ = `product_id=${product_id}&quantity=${quantity}`;
			}
		}else{
			var dados_ = `product_id=${product_id}&quantity=${quantity}`;
		}
		console.log(dados_);
		$.ajax({
			url: `${$url}/add`,
			type: 'post',
			data: dados_,
			dataType: 'json',
			beforeSend: function() {
				$('#cart > button').button('loading');
			},
			complete: function() {
				$('#cart > button').button('reset');
			},
			success: function(json) {
				$('.alert, .text-danger').remove();

				if (json['redirect']) {
					location = json['redirect'];
				}

				if (json['success']) {
					//notification.success(json['success']);

					/*window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });*/

					Swal.fire("Sucesso", "" + json["success"] + "", "success");

					if (json['count']) {
						$('#box-carrinho [data-cart="count"]').text(json['count']);
					}
					if (json['total']) {
						$('#box-carrinho [data-cart="total"]').text(json['total']);
					}


				}
			},
			error: function(xhr, ajaxOptions, thrownError) {
				alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
			}
		});
		return false;
	},
	'update': function(key, quantity) {
		checkQuantidade(quantity);

		$.ajax({
			url: `${$url}/edit`,
			type: 'post',
			data: `key=${key}&quantity[${key}]=${quantity}`,
			beforeSend: function() {
				Loader.on();
			},
			success: function(json) {
				location = $url;
			},
			error: function(xhr, ajaxOptions, thrownError) {
				Loader.off();
				location = $url;
			}
		});
	},
	'remove': function(key) {
		$.ajax({
			url: `${$url}/remove`,
			type: 'post',
			data: `key=${key}`,
			success: function(json) {
				location = $url;
			},
			error: function(xhr, ajaxOptions, thrownError) {
				location = $url;
			}
		});
	}
}

window.addEventListener('load', function(){
	$('input[data-quantidade]').on('change', function(){
		var form = this.form;
		if (form) {
			form.submit();
		}
	});
	$('[data-fn="plus"]').on('click', function(){
		var t = $($(this).data('target')),
			n = parseInt(t.val());
		t.val(n+1).trigger('change');
	});
	$('[data-fn="minus"]').on('click', function(){
		var t = $($(this).data('target')),
			n = parseInt(t.val());
		if (n > 1) {
			t.val(n-1).trigger('change');
		}
	});

});


window.cart = cart;
$('#send-contact .start-ajax').click(function() {
    $.ajax({
        url: 'index.php?route=common/sendemail/send',
        type: 'post',
        data: $('#send-contact input[type=\'text\'], #send-contact input[type=\'email\'], #send-contact textarea, #send-contact input[type=\'checkbox\']:checked'),
        dataType: 'json',
        beforeSend: function() {
            $('#send-contact .start-ajax').button('loading');
        },
        complete: function() {
            $('#send-contact .start-ajax').button('reset');
        },
        success: function(json) {

            if (json['error_name']) {
                alerta('alerta-danger',json['error_name']);
                $('#send-contact #footer-nome').focus();
            }

            if (json['error_email']) {
                alerta('alerta-danger',json['error_email']);
                $('#send-contact #footer-email').focus();
            }

            if (json['error_telefone']) {
                alerta('alerta-danger',json['error_telefone']);
                $('#send-contact #footer-telefone').focus();
            }

            if (json['error_assunto']) {
                alerta('alerta-danger',json['error_assunto']);
                $('#send-contact #footer-assunto').focus();
            }

            if (json['error_mensagem']) {
                alerta('alerta-danger',json['error_mensagem']);
                $('#send-contact #footer-mensagem').focus();
            }

            if (json['error_agree']) {
                alerta('alerta-danger',json['error_agree']);
                $('#send-contact #footer-agree').focus();
            }

            if (json['success']) {
                alerta('alerta-success',json['text_success']);
                $('#send-contact input').val('');
                $('#send-contact textarea').val('');
            }
        }
    });

    function alerta(classeTipo,msg){
        $('#notificacao').append(
            '<div class="alert '+ classeTipo +'">\n' +
            '        <button class="close" type="button" data-dismiss="alert">&times;</button>\n' +
            '        <p>'+ msg +'</p>\n' +
            '</div>'
        );
        if (classeTipo == 'alerta-danger') {
            setInterval(function () {
                $('.' + classeTipo).remove();
            }, 4000);
        }
    }

});
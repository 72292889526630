window.$on('termos.cookies', function(event) {
    console.log(event);
    jQuery.ajax({
        url: 'index.php?route=common/home/cookies',
        success: function(html) {

        }
    });
});

$(document).ready(function(){
    $("input[name='preco']").click(function(v) {
        console.log(v.target);
        window.location = $(v.target).attr('value');
    });
});
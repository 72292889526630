
// Cart
var sistemas = $('div[data-page="cart"]');
if (sistemas.length>0){
    jQuery('input[name=\'postcode\']').keydown(function(event) {
        if (event.keyCode == 13) {
            event.preventDefault();
            jQuery('#button-quote').trigger('click');
            return false;
        }
    });
    $('#button-quote').on('click', function() {
        $.ajax({
            url: 'index.php?route=extension/total/shipping/quote',
            type: 'post',
            data: 'country_id=' + $('select[name=\'country_id\']').val() + '&zone_id=' + $('select[name=\'zone_id\']').val() + '&postcode=' + encodeURIComponent($('input[name=\'postcode\']').val()),
            dataType: 'json',
            beforeSend: function() {
                $('#button-quote').attr('disabled', true).text('Aguarde...');
            },
            complete: function() {
                $('#button-quote').attr('disabled', false).text('OK');
            },
            success: function(json) {
                //$('.alert, .text-danger').remove();

                if (json['error']) {
                    if (json['error']['warning']) {
                        notification.error(json['error']['warning']);
                    }

                    if (json['error']['country']) {
                        $('#country').removeClass('hidden');
                        $('select[name=\'country_id\']').after('<div class="text-danger">' + json['error']['country'] + '</div>');
                    }

                    if (json['error']['zone']) {
                        $('#zone').removeClass('hidden');
                        $('select[name=\'zone_id\']').after('<div class="text-danger">' + json['error']['zone'] + '</div>');
                    }

                    if (json['error']['postcode']) {
                        $('input[name=\'postcode\']').parent().after('<div class="text-danger">' + json['error']['postcode'] + '</div>');
                    }
                }

                if (json['shipping_method']) {
                    $('#modal-shipping').remove();

                    html  = '<div id="modal-shipping" class="modal">';
                    html += '  <div class="modal-dialog">';
                    html += '    <div style="' +
                        'position: relative;\n' +
                        '    display: -webkit-box;\n' +
                        '    display: -ms-flexbox;\n' +
                        '    display: flex;\n' +
                        '    -webkit-box-orient: vertical;\n' +
                        '    -webkit-box-direction: normal;\n' +
                        '    -ms-flex-direction: column;\n' +
                        '    flex-direction: column;\n' +
                        '    width: 100%;\n' +
                        '    pointer-events: auto;\n' +
                        '    background-color: #fff;\n' +
                        '    background-clip: padding-box;\n' +
                        '    border: 1px solid rgba(0,0,0,.2);\n' +
                        '    border-radius: 0.3rem;\n' +
                        '    outline: 0;" class="modal-content">';
                    html += '      <div class="modal-header">';
                    html += '        <h4 class="modal-title"><?php echo $text_shipping_method; ?></h4>';
                    html += '      </div>';
                    html += '      <div class="modal-body">';

                    for (i in json['shipping_method']) {
                        html += '<p><strong>' + json['shipping_method'][i]['title'] + '</strong></p>';

                        if (!json['shipping_method'][i]['error']) {
                            for (j in json['shipping_method'][i]['quote']) {
                                html += '<div class="radio">';
                                html += '  <label>';

                                if (json['shipping_method'][i]['quote'][j]['code'] == '<?php echo $shipping_method; ?>') {
                                    html += '<input type="radio" name="shipping_method" value="' + json['shipping_method'][i]['quote'][j]['code'] + '" checked="checked" />';
                                } else {
                                    html += '<input type="radio" name="shipping_method" value="' + json['shipping_method'][i]['quote'][j]['code'] + '" />';
                                }

                                html += json['shipping_method'][i]['quote'][j]['title'] + ' - ' + json['shipping_method'][i]['quote'][j]['text'] + '</label></div>';
                            }
                        } else {
                            html += '<div class="alert alert-danger">' + json['shipping_method'][i]['error'] + '</div>';
                        }
                    }

                    html += '      </div>';
                    html += '      <div class="modal-footer">';
                    html += '        <button type="button" class="btn btn-default" data-dismiss="modal"><?php echo $button_cancel; ?></button>';

                    html += '        <input type="button" name="startFrete" value="Utilizar frete" id="button-shipping" data-loading-text="Carregando..." class="btn btn-primary" />';

                    html += '      </div>';
                    html += '    </div>';
                    html += '  </div>';
                    html += '</div> ';

                    $('body').append(html);

                    $('#modal-shipping').modal('show');

                    $('input[name=\'startFrete\']').on('change', function() {
                        // if (this.value.indexOf('seguro') > -1) {
                        // 	$('#aviso-frete').hide();
                        // } else {
                        // 	$('#aviso-frete').show();
                        // }
                        $('#button-shipping').prop('disabled', false).trigger('click');
                    });
                }
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                window.alert("Parece que estamos com uma instabilidade, por favor tente mais tarde.");
            }
        });
    });

    $(document).delegate('#button-shipping', 'click', function() {
        $.ajax({
            url: 'index.php?route=extension/total/shipping/shipping',
            type: 'post',
            data: 'shipping_method=' + encodeURIComponent($('input[name=\'shipping_method\']:checked').val()),
            dataType: 'json',
            beforeSend: function() {
                $('#button-shipping').button('loading');
            },
            complete: function() {
                $('#button-shipping').button('reset');
            },
            success: function(json) {
                $('.alert').remove();

                if (json['error']) {
                    notification.error(json['error']);
                }

                if (json['redirect']) {
                    location = json['redirect'];
                }
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
                window.alert("Parece que estamos com uma instabilidade, por favor tente mais tarde.");
            }
        });
    });
    $('select[name=\'country_id\']').on('change', function() {
        $.ajax({
            url: 'index.php?route=extension/total/shipping/country&country_id=' + this.value,
            dataType: 'json',
            beforeSend: function() {
                $('select[name=\'country_id\']').after(' <i class="fa fa-circle-o-notch fa-spin"></i>');
            },
            complete: function() {
                $('.fa-spin').remove();
            },
            success: function(json) {
                if (json['postcode_required'] == '1') {
                    $('input[name=\'postcode\']').parent().parent().addClass('required');
                } else {
                    $('input[name=\'postcode\']').parent().parent().removeClass('required');
                }

                html = '<option value=""><?php echo $text_select; ?></option>';

                if (json['zone'] && json['zone'] != '') {
                    for (i = 0; i < json['zone'].length; i++) {
                        html += '<option value="' + json['zone'][i]['zone_id'] + '"';

                        if (json['zone'][i]['zone_id'] == '<?php echo $zone_id; ?>') {
                            html += ' selected="selected"';
                        }

                        html += '>' + json['zone'][i]['name'] + '</option>';
                    }
                } else {
                    html += '<option value="0" selected="selected"><?php echo $text_none; ?></option>';
                }

                $('select[name=\'zone_id\']').html(html);
            },
            error: function(xhr, ajaxOptions, thrownError) {
                console.log(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
            }
        });
    });

    $('select[name=\'country_id\']').trigger('change');

    $('#button-coupon').on('click', function() {
        $.ajax({
            url: 'index.php?route=extension/total/coupon/coupon',
            type: 'post',
            data: 'coupon=' + encodeURIComponent($('input[name=\'coupon\']').val()),
            dataType: 'json',
            beforeSend: function() {
                $('#button-coupon').button('loading');
            },
            complete: function() {
                $('#button-coupon').button('reset');
            },
            success: function(json) {
                $('.alert').remove();

                if (json['error']) {
                    //$('#notification').html('<div class="alert alerta-danger"><i class="fa fa-exclamation-circle"></i> ' + json['error'] + '<button type="button" class="close" data-dismiss="alert">&times;</button></div>');
                    //$('html, body').animate({ scrollTop: 0 }, 'slow');

                    Swal.fire("Ops..", "" + json["error"] + "", "error");

                }

                if (json['redirect']) {
                    location = json['redirect'];
                }
            }
        });
    });
}